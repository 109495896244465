<template>
    <div class="profile_notes">
        <div class="title mb-3">
           <h3>Chat</h3>
            <div class="right_side grey">
                <button type="button" class="mr-3"><i class="fas fa-flag"></i></button>
                <button type="button" class="mr-3"><i class="fas fa-envelope-open-text"></i></button>
                <button type="button"><i class="fas fa-ellipsis-v"></i></button>
            </div>
        </div>
        <Chat/>
    </div>
</template>

<script>
import Chat from './Chat.vue';
export default{
    name: 'ProfileChat',
    components:{
        Chat
    },
    data(){
        return{
            subject: '',
        }
    },
    methods:{
        setEmoji (emoji) {
            this.subject = emoji;
        },
    }
}
</script>
